
/* Extra small devices (<768px) */
#viewer {
  h2 {
    font-size: 1.6em;
    margin: 0;
    padding: 0.7em;
  }

  section#screen {
    min-width: 100%;

    iframe {
      width: 100%;
      max-height: 300px;
    }
  }

  footer {
    padding: 0.7em;

    section.info {
      text-align: center;
      padding-top: 0.5em;

      ul {
        li {
          list-style: none;

          .fa {
            padding-right: 0.3em;
          }
        }
      }
    }
  }
}

/* Small devices (>768px) */
@media (min-width: $screen-sm-min) {
  #viewer {
    right: 250px;
    top: 50px;
    left: 0;
    bottom: 0;

    position: fixed;
    display: flex;
    flex-direction: column;

    section#screen {
      position: relative;
      flex-grow: 1;

      iframe {
        position: absolute;
        height: 100%;
        max-height: none;
      }
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  #viewer {
    right: 510px;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {

}

@media (min-width: 1400px) {
  footer {
    section.info {
      ul {
        display: inline-block;
        margin: 0;
        padding: 0;

        li {
          display: inline;
          padding: 0 2em;
        }
      }
    }
  }
}
