nav.navbar.navbar-default.navbar-fixed-top {
  @media (max-width: $screen-xs-max) {
    position: absolute;

    li form.navbar-form {
      margin: 6px 0px;
    }
  }

  .navbar-brand {
    h1 {
      display: none;
    }

    font-size: 30px;
    padding: 8px 1em;
  }

  ol.navbar-nav {
    @media (min-width: $screen-sm-min) {
      .channel:nth-child(n+5) {
        display: none;
      }
    }

    @media (min-width: $screen-md-min) {
      .channel:nth-child(5) {
        display: block;
      }
    }

    @media (min-width: $screen-lg-min) {
      .channel:nth-child(n+6) {
        display: block;
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    li.dropdown.open > ul.dropdown-menu {
      overflow-y: auto;
      max-height: 500px;
    }
  }

  li.search form.navbar-form {
    .btn {
      margin-left: 1em;
    }
  }

  li.search-open form.navbar-form {
    .btn {
      margin-top: 4px;
    }
  }

  .user-menu {
    li.setting-checkbox > a {
      padding-left: 20px;

      &.checked {
        &:before {
          @include fa-icon;
          content: $fa-var-check-square;
          padding-right: 1em;
        }
      }

      &.unchecked {
        &:before {
          @include fa-icon;
          content: $fa-var-square;
          padding-right: 1em;
        }
      }
    }
  }
}
