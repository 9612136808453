@font-face {
  font-family: 'icomoon';
  src:url('/fonts/icomoon.eot?c86vbq');
  src:url('/fonts/icomoon.eot?c86vbq#iefix') format('embedded-opentype'),
  url('/fonts/icomoon.ttf?c86vbq') format('truetype'),
  url('/fonts/icomoon.woff?c86vbq') format('woff'),
  url('/fonts/icomoon.svg?c86vbq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-logo:before {
  content: "\e800";
}

