/* Extra small devices (<768px) */
.item-list {
  width: 100%;
  clear: both;
}

.item {
  position: relative;
  cursor: pointer;

  h4 {
    display: inline;
    background-color: black;
    padding: 5px;
    opacity: 0.7;
    color: white;
    position: absolute;
    bottom: 0;
    border-radius: 0px 3px 3px 0px;
    font-size: 18px;
    width: 100%;
  }

  .item-thumbnail {
    height: 200px;

    img {
      min-height: 100%;
      min-width: 100%;
      opacity: 0;
    }

    overflow: hidden;
    background: no-repeat center center;
  }

  &.active {
    h4 {
      opacity: 1.0;
      color: orangered;
      background-color: white;

      &:before {
        @include fa-icon();
        content: $fa-var-arrow-up + "\00a0\00a0";
      }
    }

    cursor: inherit;
    border-right: 2px orangered solid;
  }

  &:hover, &:active {
    h4 {
      opacity: 1.0;
      background-color: #111;
    }

    &.active {
      h4 {
        background-color: white;
      }
    }
  }
}

/* Small devices (>768px) */
@media (min-width: $screen-sm-min) {
  .item-list {
    width: 240px;
    clear: right;

    h2 {
      display: none;
    }
  }

  .item {
    &.active {
      h4 {
        &:before {
          @include fa-icon();
          content: $fa-var-arrow-left + "\00a0\00a0";
        }
      }
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  .item-list {
    width: 480px;
  }

  .item {
    .item-thumbnail {
      height: 270px;
    }
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {

}
