@import "base";

body {
  padding-top: 50px;
}

#tv > .container-fluid {
  padding: 0;
}

